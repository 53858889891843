@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  /* font-family: */
}

.navbar {

}

.navbar h1 {
  width:20%;
}

.navbar.selected {
  color: green;
}

.navbar a {
  text-decoration: none;
  padding: 2rem; 
}

.container {
  display:flex;
  width: 100%; 
  padding: 1.25rem;  
  justify-content: space-evenly;
  align-items: center;
}

.row {
  display: flex;
  justify-content: space-evenly;
}

.card {
  border-radius: 1rem;
  padding:1.25rem;
} 

.cardButton{
  border-radius: 1rem;
  background-color: aliceblue;
}

.cardButtonText{
  display: flex;
  align-items: center;
  padding:1rem;
}

.cardButton:hover{
  background-color: green;
}

.tug-of-war{
  background-image: url('./resources/camps/01-夏令营拔河.jpg');
  height:40rem;
}

.contest{
  background-image: url('./resources/events/contest.JPG');
  /* width:100%;
  padding-top: 40%; */
}

.bc{
  background-image: url('./resources/events/2018年-极易中文学校在Bellevue\ College的教学.jpg');
}

image{
  object-fit:cover;
}

.gallery-button{
  width:50px;
  height:8px;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.gallery{
    width:900px;
    display:flex;
    overflow-x: scroll;
}

.gallery div{
    width:100%;
    display:grid;
    grid-template-columns: auto auto auto;
    grid-gap:20px;
    padding:10px;
    flex:none;
    align-items: center;
}

.gallery div img {
    width:100%;
} 
.gallery::-webkit-scrollbar{
  display:none;
}